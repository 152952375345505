<template>
  <div class="box-content">
    <mcp-modal :show="show" @closeModal="showModal">
      <add-request
        :operators="operators"
        :callBackFunction="refreshRefunds"
        @closeModal="showModal"
      />
    </mcp-modal>
    <search :makeSearch="makeSearch" :showSearch="false">
      <template #buttons>
        <button
          v-if="$isMcp()"
          class="button is-outlined is-small"
          @click="showForm"
        >
          Faire une demande
        </button>
        <button @click="refreshRefunds" class="button is-outlined is-small">
          Actualiser
        </button>
      </template>
    </search>
    <data-table
      :columns="columns"
      :loading="loading"
      :counter="refunds.length"
      :meta="meta"
      :navigationFunction="navigationFunction"
    >
      <template #body>
        <tbody>
          <tr v-for="(item, key) in refunds" :key="key">
            <td>{{ item.operator }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.amount }}</td>
            <td>
              <span v-if="item.state == 1" class="tag is-success is-light"
                >Remboursé</span
              >
              <span v-else class="tag is-primary is-light">Soumit</span>
            </td>
            <td>{{ item.created_at }}</td>
            <td>{{ item.refund_at }}</td>
            <td>
              <div class="d-flex">
                <mcp-tooltip title="Supprimer">
                  <i
                    v-if="item.state == 0"
                    @click="removeRefund(item)"
                    class="fa-solid fa-remove"
                  ></i>
                </mcp-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </data-table>
  </div>
</template>

<script>
import dataTable from "@/components/table/datatable";
import search from "@/components/table/search";
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import mcpModal from "@/components/McpModal";
import addRequest from "./add.vue";
export default {
  name: "refunds",
  components: {
    dataTable,
    search,
    mcpModal,
    addRequest,
  },
  data: () => ({
    loading: false,
    show: false,
    columns: [
      { name: "operator", label: "Opérateur" },
      { name: "phone", label: "Numéro" },
      { name: "amount", label: "Montant" },
      { name: "state", label: "Statut" },
      { name: "created_at", label: "Date de création" },
      { name: "refund_at", label: "Date de remboursement" },
      { name: "action", label: "Actions" },
    ],
    model: {
      page: 1,
      date_debut: "",
      date_fin: "",
      state: "",
    },
    operators: [],
  }),

  computed: {
    ...mapGetters({
      refunds: `${TYPES.modules.REFUNDS}/${TYPES.getters.GET_REFUNDS}`,
      meta: `${TYPES.modules.REFUNDS}/${TYPES.getters.GET_REFUNDS_META}`,
      //operators: `${TYPES.modules.UTILS}/${TYPES.getters.GET_OPERATOR}`,
    }),
  },

  mounted() {
    this.getRefunds();
  },

  methods: {
    navigationFunction(page) {
      this.current.page = page;
      this.getRefunds();
    },

    getRefunds() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.REFUNDS + "/" + TYPES.actions.GET_REFUNDS}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    makeSearch(search) {
      this.model.page = 1;
      this.loading = true;
      this.model.search = search;
      this.$store
        .dispatch(
          `${TYPES.modules.REFUNDS + "/" + TYPES.actions.GET_REFUNDS}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    refreshRefunds() {
      this.loading = true;
      this.model.date_debut = "";
      this.model.date_fin = "";
      this.model.state = "";
      this.model.page = 1;
      this.$store
        .dispatch(
          `${TYPES.modules.REFUNDS + "/" + TYPES.actions.GET_REFUNDS}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    removeRefund(refund) {
      if (confirm("Voulez vous vraiement supprimer ?")) {
        this.loading = true;
        this.$store
          .dispatch(
            `${TYPES.modules.REFUNDS + "/" + TYPES.actions.DELETE_REFUND}`,
            refund
          )
          .then((res) => {
            if (res) {
              this.loading = false;
              this.$toast.success(`Opération éffectuée`);
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },

    showModal() {
      this.show = !this.show;
    },

    getOperators() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_OPERATOR}`
      );
    },

    showForm() {
      this.showModal();
      //this.getOperators();
      setTimeout(() => {
        this.operators = [
          { bankcode: "MTNMoMo", id: 1 },
          { bankcode: "Moov", id: 2 },
        ];
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>