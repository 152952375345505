<template>
  <div>
    <h2>Demande de remboursement</h2>
    <spinner v-if="status" :show="true" color="#CF0C1A" />
    <div v-else>
      <div class="pt-10">
        <label>Opérateurs</label>
        <v-select
          :options="operators"
          placeholder="Selectionner"
          v-model="form.operator"
          label="bankcode"
          :reduce="(item) => item.bankcode"
        ></v-select>
        <div
          v-if="errors.operator"
          :class="errors.operator.length > 0 ? 'invalid-feedback' : ''"
        >
          <strong class="has-text-danger" style="font-size: 12px">{{
            errors.operator[0]
          }}</strong>
        </div>
      </div>
      <div>
        <div class="pt-10">
          <text-input
            type="number"
            :errors="errors.phone"
            v-model="form.phone"
            label="Numéro"
          />
        </div>
        <div class="pt-10">
          <text-input
            type="text"
            :errors="errors.amount"
            v-model="form.amount"
            label="Montant"
          />
        </div>
      </div>
      <div class="pt-10 d-flex-right">
        <button
          @click="addRequest"
          :disabled="loading"
          :class="
            loading
              ? 'button is-responsive is-loading is-primary'
              : 'button is-responsive is-primary'
          "
        >
          Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput";
import { TYPES } from "@/store/types";
import Spinner from "@/components/Spinner";
const Default_form = {
  operator: "",
  phone: "",
  amount: "",
  comment: "",
};
export default {
  name: "addRequest",
  components: { TextInput, Spinner },
  props: {
    operators: {
      type: Array,
      default: () => [],
    },
    callBackFunction: {
      type: Function,
    },
  },

  data: () => ({
    errors: [],
    loading: false,
    form: {
      operator: "",
      phone: "",
      amount: "",
      comment: "",
    },
  }),

  computed: {
    status() {
      if (this.operators.length === 0) {
        return true;
      } else return false;
    },
  },

  methods: {
    addRequest() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.REFUNDS + "/" + TYPES.actions.ADD_REFUND}`,
          this.form
        )
        .then((res) => {
          this.errors = [];
          this.loading = false;
          this.form = { ...Default_form };
          this.$emit("closeModal");
          this.callBackFunction();
          this.$toast.success(`Opération effectuée`);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
};
</script>